import { useEffect, useState } from "react";
import "../styles/progressiveKanban.css";
import { useRecoilValue } from "recoil";
import { COLORS } from "../types";
import { progressivePhasesAtom, currentProgressivePhaseAtom, progressiveTasksAtom, progressiveWorkshiftsAtom, progressiveLimits } from "../state/misc";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, Label, Tooltip, TooltipProps } from 'recharts';
import ProgressBar from "@ramonak/react-progress-bar";

type LineData = {
  areaId: number;
  durationDiff: number;
  id: number;
  isInProgress: boolean;
  realDuration: number;
  reportedDuration: number | null;
  reportedStart: number | null;
  reportedStop: number | null;
  start: string;
  stop: number | null;
  targetDuration: number;
  targetStart: string | null;
  targetStop: string | null;
  taskId: number;
  type: string;
};
type DotData = {
  key: string,
  cx: number,
  cy: number,
  payload: LineData
};
const convertMsToHoursAndMinutes = (ms: number) => {
  let hours = Math.floor((ms % 86400000) / 3600000);
  let minutes = Math.round(((ms % 86400000) % 3600000) / 60000);
  return { hours, minutes };
};

const convertMsToPrettyString = (ms: number) => {

  let { hours, minutes } = convertMsToHoursAndMinutes(ms);
  let minStr = minutes + " min";
  if (hours == 0) return minStr;
  return hours + " h " + minStr;
};

const ProgressiveKanban = () => {

  const now = new Date().getTime();


  const shifts = useRecoilValue(progressiveWorkshiftsAtom);
  let currentShift = null;
  for (const workshift of shifts) {
    if (workshift.start == null || workshift.stop == null) continue;
    const start = new Date(workshift.start).getTime();
    if (start > now) continue;
    const stop = new Date(workshift.stop).getTime();
    if (stop < now) continue;
    currentShift = workshift;
    break;
  }

  const currentPhase = useRecoilValue(currentProgressivePhaseAtom);
  const limits = useRecoilValue(progressiveLimits);
  const phases = useRecoilValue(progressivePhasesAtom);
  const [renderTime, setNextRenderTime] = useState(0);


  const tasks = useRecoilValue(progressiveTasksAtom);

  //Re-render once a minute
  useEffect(() => {
    let next = renderTime + 1;
    setTimeout(function () {
      setNextRenderTime(next);
    }, 60 * 1000);
  }, [renderTime]);

  if (phases == null || phases.length == 0) {
    return <p>No phases configured</p>;
  }

  if (currentPhase == null) return <p>No phase selected</p>;

  if (currentShift == null || currentShift.start == null || currentShift.stop == null) return <p>No ongoing workshift</p>;

  let diffMsWorkshift = new Date(currentShift.stop).getTime() - new Date(currentShift.start).getTime();
  let totalMinutesInWorkShift = Math.round(diffMsWorkshift / 1000 / 60);

  let diffMs = new Date(currentShift.stop).getTime() - new Date().getTime();
  let { hours, minutes } = convertMsToHoursAndMinutes(diffMs);
  let timeClass = "progress-wrapper progress-wrapper-upper";
  let timeLabel = hours + " tuntia ja " + minutes + " minuuttia jäljellä";
  let minutesCompleted = totalMinutesInWorkShift - hours * 60 - minutes;
  let timePercentage = minutesCompleted / totalMinutesInWorkShift;

  if (timePercentage > 0.85) {
    timeClass += " progress-wrapper-right";
  }
  if (timePercentage < 0.15) {
    timeClass += " progress-wrapper-left";
  }
  if (hours == 0) {
    timeLabel = minutes + " minuuttia jäljellä";
  }

  let subtask = currentShift.subtasks.find(a => a.type == currentPhase.id);
  let timeIsFurther = false;
  let taskBar = null;
  let timeBar = null;
  if (subtask != null) {
    let taskLabel = subtask.completedAmount + " / " + subtask.targetAmount + " projektia tehtynä";
    let taskClass = "progress-wrapper progress-wrapper-lower";
    const taskPercentage = subtask.completedAmount / subtask.targetAmount;
    if (taskPercentage > 0.85) {
      taskClass += " progress-wrapper-right";
    }
    if (taskPercentage < 0.15) {
      taskClass += " progress-wrapper-left";
    }
    const progressProps = {
      animateOnRender: true,
      height: "50px",
      width: "95%",
      transitionDuration: "1s"
    };
    timeIsFurther = timePercentage > taskPercentage;
    timeBar = <ProgressBar
      {...progressProps}
      className={timeClass}
      completed={timePercentage * 100}
      customLabel={timeLabel}
      bgColor={COLORS.Dna.Musta}
      baseBgColor={timeIsFurther ? COLORS.Dna.VaaleanHarmaa : "transparent"}
    />;
    taskBar = <ProgressBar
      {...progressProps}
      className={taskClass}
      completed={taskPercentage * 100}
      customLabel={taskLabel}
      bgColor={COLORS.Dna.KuumaPinkki}
      baseBgColor={!timeIsFurther ? COLORS.Dna.VaaleanHarmaa : "transparent"}

    />;
    const shiftStart = new Date(currentShift.start);
    let time = new Date(shiftStart);
    time.setMinutes(0, 0, 0);
  }

  let minLimit = -20;
  let maxLimit = 20;
  let target = 0;
  let min = -10;
  let max = 25;
  if (limits != null) {
    minLimit = limits.lowerControlLimit * -1;
    maxLimit = limits.upperControlLimit;
    min = limits.lowerLimit * -1;
    max = limits.upperLimit;
  }


  let data = [];
  let refs = [];
  let time = new Date(currentShift?.start);
  time.setMinutes(0, 0, 0)
  const shiftStop = new Date(currentShift.stop);
  let v = 0;
  while (time.getTime() <= shiftStop.getTime()) {
    v += 1;
    refs.push(<ReferenceLine key={v} x={time.getTime()} label={<Label value={time.getHours() + ":" + "00"} offset={0} position="insideBottom" />} stroke={COLORS.Dna.VaaleanHarmaa} strokeWidth={2} />);
    time = new Date(time.getTime() + 3 * 60 * 60 * 1000);
  }
  let useRawDuration = false;
  if (tasks == null) return null;

  for (const task of tasks) {

    for (const subtask of task.subtasks) {
      if (task.completed && subtask.stop == null) continue;
      if (subtask.start == null) continue;
      if (subtask.type != currentPhase.id) continue;

      let start = new Date(subtask.start);
      let isInProgress = subtask.stop == null;
      let stop = subtask.stop != null ? new Date(subtask.stop) : new Date();

      if (stop < new Date(currentShift?.start) || stop > shiftStop) continue;

      let realDuration = stop.getTime() - start.getTime();
      let targetDuration = null;
      let reportedDuration = null;
      let durationDiff = null;

      if (subtask.reportedStart != null && subtask.reportedStop != null) {
        reportedDuration = new Date(subtask.reportedStop).getTime() - new Date(subtask.reportedStart).getTime();
      }

      if (subtask.targetStart != null && subtask.targetStop != null) {
        targetDuration = new Date(subtask.targetStop).getTime() - new Date(subtask.targetStart).getTime();
        let diff = realDuration - targetDuration;
        durationDiff = diff / targetDuration * 100;
      } else {
        useRawDuration = true;
        durationDiff = realDuration;
      }

      data.push({
        ...subtask,
        isInProgress,
        stop: stop.getTime(),
        realDuration,
        targetDuration,
        reportedDuration,
        durationDiff
      });
    }
  }


  data.sort((a, b) => a.stop - b.stop);


  const renderDot = (props: DotData) => {
    const radius = 5;
    let color = COLORS.Dna.Musta;
    let animation = null;
    if (props.payload.isInProgress) {
      color = COLORS.Dna.KuumaPinkki;
      animation = <animate attributeName="r" values={`${radius};${radius * 1.5};${radius * 2};${radius}`} dur="1s" repeatCount="indefinite" />;
    }
    return <circle key={Math.random()} cx={Number(props.cx)} cy={Number(props.cy)} r={radius.toString()} fill={color} >
      {animation}
    </circle>
  };
  const CustomTooltip = (props: TooltipProps<number, number>) => {
    if (props.active && props.payload && props.payload.length) {
      if (props.payload[0].value == null) return null;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Valmistunut: ${props.payload[0].payload.isInProgress ? "kesken" : new Date(props.payload[0].payload.stop).toLocaleString()}`}</p>
          <p className="label">{`Toteutunut kesto: ${convertMsToPrettyString(props.payload[0].payload.realDuration)}`}</p>
          {props.payload[0].payload.targetDuration && <p className="label">{`Suunniteltu kesto: ${convertMsToPrettyString(props.payload[0].payload.targetDuration)}`}</p>}
          <p className="label">{`Raportoitu kesto: ${convertMsToPrettyString(props.payload[0].payload.reportedDuration)}`}</p>
        </div>
      );
    }

    return null;
  };
  let domain = undefined;
  if (!useRawDuration) {
    domain = [min, max];
  }
  return (
    <>
      {subtask != null ? (
        <>
          {timeIsFurther ? (<>{timeBar}{taskBar}</>) : (<>{taskBar}{timeBar}</>)}
          <ResponsiveContainer width="80%" height="60%">
            <LineChart data={data}>
              <Tooltip content={<CustomTooltip />} />
              {refs}
              {!useRawDuration && <><ReferenceLine
                y={maxLimit}
                label={<Label value={`Max (${maxLimit} %)`} offset={10} position="insideBottomLeft" />}
                stroke={COLORS.Dna.KuumaPinkki}
                strokeWidth={3}
                strokeDasharray="6 3" />
                <ReferenceLine y={target} label={<Label value="Target" offset={10} position="insideBottomLeft" />} stroke={COLORS.Dna.Musta} strokeWidth={3} />
                <ReferenceLine y={minLimit} label={<Label value={`Min (${minLimit} %)`} offset={10} position="insideBottomLeft" />} stroke={COLORS.Dna.KuumaPinkki} strokeWidth={3} strokeDasharray="6 3" /></>}

              <Line type="linear" dataKey="durationDiff" stroke={COLORS.Dna.KuumaPinkki} strokeWidth={3} dot={renderDot} activeDot={{ stroke: COLORS.Dna.KuumaPinkki, strokeWidth: 2, r: 5, fill: "transparent" }} isAnimationActive={false} />
              <XAxis type="number" hide={true} dataKey="stop" domain={[new Date(currentShift.start).getTime() - 20 * 60 * 1000, new Date(currentShift.stop).getTime() + 20 * 60 * 1000]} />
              <YAxis type="number" hide={true} dataKey="durationDiff" domain={domain} allowDataOverflow={true} />

            </LineChart>
          </ResponsiveContainer></>) : (<p>No tasks for this phase</p>)}
    </>);
};

export default ProgressiveKanban;
