import ProgressiveKanban from "../components/ProgressiveKanban";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { progressivePhasesAtom, currentProgressivePhaseAtom, progressiveWorkshiftsAtom } from "../state/misc";
import { Translations } from "../hooks";
import FixedSelect from "../components/Select";
import "../styles/progressiveKanban.css";
import { COLORS } from "../types";

type Props = { translations: Translations };
export const ProgressiveKanbanView = ({ translations }: Props) => {


  const now = new Date().getTime();


  const shifts = useRecoilValue(progressiveWorkshiftsAtom);
  let currentShift = null;
  for (const workshift of shifts) {
    if (workshift.start == null || workshift.stop == null) continue;
    const start = new Date(workshift.start).getTime();
    if (start > now) continue;
    const stop = new Date(workshift.stop).getTime();
    if (stop < now) continue;
    currentShift = workshift;
    break;
  }

  const currentPhase = useRecoilValue(currentProgressivePhaseAtom);
  const setCurrentPhase = useSetRecoilState(currentProgressivePhaseAtom);
  const phases = useRecoilValue(progressivePhasesAtom);

  if (phases == null || phases.length == 0) {
    return <p>No phases configured</p>;
  }

  if (currentPhase == null) return <p>No phase selected</p>;

  if (currentShift == null || currentShift.start == null || currentShift.stop == null) return <p>No ongoing workshift</p>;

  const options = phases.filter(a => !a.buffer).map((element) => {
    return {
      value: element.id,
      label: element.name
    }
  });


  const currentOpt = options.find(a => a.value == currentPhase.id);

  if (currentOpt == null) return <p>Couldn't find selected phase from options</p>;
  const height = 80;
  const styles = {
    control: (style: any) => ({
      // none of react-select's styles are passed to <Control />
      ...style,
      height,
      width: "auto",
      borderWidth: 0,
      background: COLORS.Dna.KuumaPinkki,
      boxShadow: 'none'
    }),
    indicatorsContainer: (style: any) => ({
      ...style,
      width: "auto",
      height
    }),
    input: (styles: any) => ({
      ...styles,
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      height
    }),
    placeholder: (styles: any) => ({
      ...styles,
    }),
    singleValue: (styles: any, state: any) => ({
      ...styles,
      color: COLORS.Dna.PuhdasValkoinen,
      fontSize: "32px"
    }),
    menu: (styles: any) => ({
      ...styles
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: COLORS.Dna.PuhdasValkoinen
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      width: 0
    }),
  };
  return (
    <div className="kanban-container">
      <h1 className="text-3xl font-bold pb-6">{translations({ id: "Worksheet" })}</h1>
      <div className="kanban-page-container">
        <div className="kanban-card-container">
          <div className="kanban-card">
            <div className="kanban-card-header">
              <FixedSelect
                t={null}
                height={height}
                styles={styles}
                isSearchable={false}
                isDisabled={false}
                options={options}
                value={currentOpt}
                autoFocus={true}
                wrapperClass={"flex flex-row items-center"}
                onChange={(e: any) => {
                  if (e) {
                    let phase = phases.find(a => a.id == e.value);
                    if (phase != null) setCurrentPhase(phase);
                  }
                }}
              />
            </div>
            <ProgressiveKanban />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressiveKanbanView;
